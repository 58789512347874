var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sheet"},[_c('div',{staticClass:"item-title"},[_c('img',{staticClass:"descriptions-icon",attrs:{"src":require("@/assets/img/titleIcon.png")}}),_c('span',{staticClass:"descriptions-title",staticStyle:{"margin-left":"8px","margin-bottom":"14px","font-size":"16px"}},[_vm._v("资产列表")]),(_vm.assetMGtObj.checkExport || [110, 103, 109].includes(_vm.userRoleId))?_c('el-button',{staticStyle:{"background":"#81b337","color":"#fff","float":"right"},on:{"click":_vm.handleExport}},[_vm._v("下载报告")]):_vm._e()],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"data":_vm.tableData,"stripe":"","element-loading-text":_vm.loadingText}},[_c('el-table-column',{attrs:{"prop":"is_inventory","label":"盘点状态","width":"90","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{style:({color:row.is_inventory == '未盘' ? '#BD3124' : '#81B337'})},[_vm._v(_vm._s(row.is_inventory ? row.is_inventory : '/'))])]}}])}),_c('el-table-column',{attrs:{"prop":"asset_no","label":"固定资产编号","width":"150","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.asset_no ? row.asset_no : '/'))])]}}])}),_c('el-table-column',{attrs:{"prop":"instrument_id","label":"仪器序列号","width":"110","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.instrument_id ? row.instrument_id : '/'))])]}}])}),_c('el-table-column',{attrs:{"prop":"platform_unique","label":"仪器型号","min-width":"170","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.platform_unique ? row.platform_unique : '/'))])]}}])}),_c('el-table-column',{attrs:{"prop":"asset_status_name","label":"资产状态","width":"100","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.asset_status_name ? row.asset_status_name : '/'))])]}}])}),_c('el-table-column',{attrs:{"prop":"area_info","label":"所在区域","min-width":"100","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.area_info ? row.area_info : '/'))])]}}])}),_c('el-table-column',{attrs:{"prop":"hospital_name","label":"医院名称","min-width":"290","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.hospital_name ? row.hospital_name : '/'))])]}}])}),_c('el-table-column',{attrs:{"prop":"level","label":"医院等级","min-width":"100","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.hospital_level ? _vm.hospitalLevelOptions.filter(function (item) { return item.val == row.hospital_level; })[0].name : '/'))])]}}])}),_c('el-table-column',{attrs:{"prop":"hospital_adress","label":"医院地址","min-width":"300","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.hospital_address ? row.hospital_address : '/'))])]}}])}),_c('el-table-column',{attrs:{"prop":"label","label":"仪器位置","width":"130","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.label_id ? _vm.locationTagOptions.filter(function (item) { return item.val == row.label_id; })[0].name : '/'))])]}}])}),_c('el-table-column',{attrs:{"prop":"customize_label","label":"仪器位置标签","width":"160","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.customize_label ? row.customize_label : '/'))])]}}])}),_c('el-table-column',{attrs:{"prop":"position_now_name","label":"现存位置","width":"90","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.position_now_name ? row.position_now_name : '/'))])]}}])}),_c('el-table-column',{attrs:{"prop":"location_info","label":"定位信息","width":"120","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.lac_lng)?_c('div',[_c('p',{staticStyle:{"font-size":"10px"}},[_vm._v("LAC:"+_vm._s(row.lac_lng ? (row.lac_lng.lac? row.lac_lng.lac : '/' ) :'/'))]),_c('span',{staticStyle:{"font-size":"10px"}},[_vm._v("CELLID:"+_vm._s(row.lac_lng ? (row.lac_lng.callID ? row.lac_lng.callID : '/') : '/'))])]):_c('span',[_vm._v("/")])]}}])}),_c('el-table-column',{attrs:{"prop":"primary_distributor","label":"一级经销商","width":"290","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.primary_distributor ? row.primary_distributor : '/'))])]}}])}),_c('el-table-column',{attrs:{"prop":"terminal_distributor","label":"终端经销商","width":"240","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.terminal_distributor)?_c('p',[_vm._v(_vm._s(row.terminal_distributor))]):_c('span',[_vm._v("/")])]}}])}),_c('el-table-column',{attrs:{"prop":"reagent_total","label":"试剂总用量","width":"110","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.reagent_total ? row.reagent_total : '/'))])]}}])}),_c('el-table-column',{attrs:{"prop":"repair_total","label":"维修总计","width":"90","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.repair_total ? row.repair_total : '/'))])]}}])}),_c('el-table-column',{attrs:{"prop":"upper_version","label":"上位机版本号","width":"200","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.upper_version ? row.upper_version : '/'))])]}}])}),_c('el-table-column',{attrs:{"prop":"latest_update_time","label":"最后更新时间","width":"200","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.latest_update_time ? row.latest_update_time : '/'))])]}}])}),_c('el-table-column',{attrs:{"prop":"engineer_name","label":"售后工程师","width":"110","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.engineer_name ? row.engineer_name : '/'))])]}}])}),_c('el-table-column',{attrs:{"prop":"saler_name","label":"销售人员","width":"90","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.saler_name ? row.saler_name : '/'))])]}}])}),_c('el-table-column',{attrs:{"prop":"check_name","label":"盘点人员","width":"90","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.check_name)?_c('p',[_vm._v(_vm._s(row.check_name))]):_c('span',[_vm._v("/")])]}}])}),_c('el-table-column',{attrs:{"prop":"check_time","label":"盘点时间","width":"130","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.check_time)?_c('p',[_vm._v(_vm._s(row.check_time))]):_c('span',[_vm._v("/")])]}}])}),_c('el-table-column',{attrs:{"prop":"check_name","label":"是否有更新","width":"100","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.is_change == 1 ? '是' : '否'))])]}}])})],1),_c('div',{staticClass:"el_pagination_style"},[_c('el-pagination',{attrs:{"hide-on-single-page":_vm.total <= 0,"background":"","layout":"total,sizes,prev, pager, next,jumper","total":_vm.total,"page-sizes":[10, 20, 30, 50],"page-size":_vm.size,"current-page":_vm.page},on:{"update:currentPage":function($event){_vm.page=$event},"update:current-page":function($event){_vm.page=$event},"current-change":_vm.handleCurrentChange,"size-change":_vm.handleSizeChange}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }