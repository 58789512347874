<template>
  <div class="sheet">
    <div class="item-title">
      <img class="descriptions-icon" src="@/assets/img/titleIcon.png">
      <span class="descriptions-title"  style="margin-left: 8px;margin-bottom: 14px">资产列表</span>
      <span><el-button @click="goConfirm" type="primary" class="confirm-btn">开始盘点</el-button></span>

    </div>
    <el-table :data="tableData" stripe  v-loading="loading" @selection-change="handleSelectionChange">
      <el-table-column type="selection" disabled width="55" fixed="left"></el-table-column>
      <el-table-column prop="is_inventory" label="盘点状态" width="90" align="center">
        <template v-slot="{row}">
         <span :style="{color:row.is_inventory == '未盘' ? '#BD3124' : '#81B337'}">{{row.is_inventory ? row.is_inventory : '/'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="instrument_id" label="仪器序列号" width="130" align="center">
        <template v-slot="{row}">
          <span>{{row.instrument_id ? row.instrument_id : '/'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="platform_unique" label="仪器型号"  min-width="140" align="center">
        <template v-slot="{row}">
          <span>{{row.platform_unique ? row.platform_unique : '/'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="asset_status" label="资产状态"  width="100" align="center">
        <template v-slot="{row}">
          <span>{{row.asset_status_name ? row.asset_status_name : '/'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="hospital_name" label="医院"  min-width="270" align="center">
        <template v-slot="{row}">
          <span>{{row.hospital_name ? row.hospital_name : '/'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="hospital_leve" label="医院等级"  min-width="100" align="center">
        <template v-slot="{row}">

          <span>{{row.hospital_level ? hospitalLevelOptions.filter(item => item.val == row.hospital_level)[0].name  : '/'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="label" label="仪器位置"  width="100" align="center">
        <template v-slot="{row}">
          <span>{{row.label_id ? locationTagOptions.filter(item => item.val == row.label_id)[0].name : '/'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="customize_label" label="仪器位置标签"  width="130" align="center">
        <template v-slot="{row}">
          <span>{{row.customize_label ?  row.customize_label : '/'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="position_now" label="现存位置"  width="90" align="center">
        <template v-slot="{row}">
          <span>{{row.position_now ? handlePosition(row.position_now).positionName : '/'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="primary_distributor" label="一级经销商"  min-width="230" align="center">
        <template v-slot="{row}">
          <span>{{row.primary_distributor ? row.primary_distributor : '/'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="terminal_distributor" label="终端经销商"  width="380" align="center">
        <template v-slot="{row}">
          <span>{{row.terminal_distributor ? row.terminal_distributor : '/'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="check_name" label="盘点人员"  width="90" align="center">
        <template v-slot="{row}">
          <span>{{row.check_name ? row.check_name : '/'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="check_time" label="盘点时间"  width="250" align="center">
        <template v-slot="{row}">
          <span>{{row.check_time ? row.check_time : '/'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="remarks" label="盘点备注"  width="160" align="center">
        <template v-slot="{row}">
          <span>{{row.remarks ? row.remarks : '/'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="is_change" label="是否有更新" width="120" align="center">
        <template v-slot="{row}">
          <span>{{row.is_change == 1 ? '是' : '否'}}</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="el_pagination_style">
      <el-pagination
          :hide-on-single-page="total <= 0"
          background
          layout="total,sizes,prev, pager, next,jumper"
          :total="total"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="size"
          :current-page.sync="page"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
      />
    </div>

    <el-dialog
        title="盘点确认"
        :visible="true"
        v-if="dialogVisible"
        width="90%"
        min-height="500px"
        top="50px"
        @close="closeConfirm"
    >
      <ac-confirm ref="acConfirm" @getIsSubmit="getIsSubmit" :rowData="selectedRows"></ac-confirm>
      <span slot="footer" class="dialog-footer" style="text-align: center">
        <el-button :disabled="!isSubmit" @click="confirmSubmit()" type="primary" size="small">确认提交</el-button>
        <el-button @click="closeConfirm" size="small">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import acConfirm from "@/views/AssetCheck/acConfirm.vue";
import checkCommon from "@/views/AssetCheck/mixin/checkCommon";
import options from "@/views/AssetManagement/mixin/options"
import {checkConfirm} from "@/api/assetCheck";
export default {
  name: "acDtlTable",
  components: {acConfirm},
  mixins: [checkCommon,options],
  data(){
    return{
      isSubmit: true, // 图片没上传完成不能提交
      loading: false,
      dialogVisible: false,
      page: 1,
      size: 10,
      total: 0,
      selectedRows: [],
      tableData: [],
      tableDataTemp: [],
      confirmList: [],
    }
  },
  created() {
    this.getTableList()
  },
  methods:{
    handleSelectionChange(val) {
      this.selectedRows = val
    },
    handleStatus(val){
      let statusName = ''
      switch (val){
        case '1001':
            statusName = '已盘'
            break;
        case '1002':
          statusName = '未盘'
          break;
      }
      return statusName
    },
    confirmSubmit(){
      const loading = this.$loading({
        lock: true,
        text:  '提交中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let confirmList = this.$refs.acConfirm.tableData
      confirmList.forEach(item=>{
        item.attachment_url = item.fileList.map(file => {
          return file.url
        })
      })
      let params = {
        note_id: Number(this.$route.query.note_id),
        data_list: confirmList
      }
      checkConfirm(params).then(res => {
        this.$message.success('提交成功！')
        loading.close()
        this.dialogVisible = false
        this.getTableList()
        this.$emit('updateDetail',true)
      }).catch(err => {
        loading.close()
      })
    },
    getIsSubmit(val){
      this.isSubmit = val
    },
    goConfirm(){
      if(this.$route.query.note_status == 1501){
        if(this.selectedRows.length > 0){
          this.dialogVisible = true
        }else{
          this.$message.warning('请选择需要盘点的仪器！')
        }
      }else{
        this.$message.warning('盘点未开始！')
      }
    },
    closeConfirm(){
      this.$forceUpdate()
      this.$set(this,'tableData',this.tableDataTemp)
      this.dialogVisible = false
    },
    handleSizeChange(val){
      this.size = val
      // sessionStorage.setItem('pageParams', JSON.stringify({ pageParams:this.pageParams }));
      this.getTableList();
    },
    handleCurrentChange(val) { // 翻页
      this.page = val
      this.getTableList()
    },
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/asset';
::v-deep .el-table th:first-child.el-table__cell>.cell{
  padding-left:14px!important;
}
::v-deep .el-table__fixed::before {
  width: 0;
}
::v-deep .el-dialog__footer{
  text-align: center;
}
::v-deep .el-pagination__sizes{
  width: 80px;
  vertical-align: top;
}
::v-deep .el-pagination__sizes .el-select{
  vertical-align: top;
}
::v-deep .el-pagination__sizes input{
  width: 80px;
  height: 1.8rem;
  border: 1px solid #999999;
}
::v-deep .el-pagination .el-input__suffix{
  right: 22px;
}
::v-deep .el-range-editor--small .el-range-input{
  font-size: 0.75rem!important;
}
.sheet{
  position: relative;
}
.confirm-btn{
  //position: absolute;
  //bottom: 25px;
  float:right;
}
</style>