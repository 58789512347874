<template>
  <div >
    <div>
      <el-descriptions title="" :column="5" :colon="false">
        <template slot="title">
          <img class="descriptions-icon" src="@/assets/img/titleIcon.png">
          <h4 class="descriptions-title"  style="margin-left: 8px;font-size:16px">基础信息</h4>
        </template>
        <el-descriptions-item label="盘点单编号：">{{formData.note_no}}</el-descriptions-item>
        <el-descriptions-item label="盘点单名称：">{{formData.title}}</el-descriptions-item>
        <el-descriptions-item label="盘点时间：">{{formData.start_time ? formData.start_time.slice(0,10) :''}}~{{formData.end_time ? formData.end_time.slice(0,10) : ''}}</el-descriptions-item>
        <el-descriptions-item label="应盘总数：">{{formData.total}}台</el-descriptions-item>
        <el-descriptions-item label="已盘总数：">{{formData.complete_total}}台</el-descriptions-item>
        <el-descriptions-item label="备注：" span="5">{{formData.remarks ? formData.remarks : '无' }}</el-descriptions-item>
      </el-descriptions>
      <el-descriptions title="" :column="1" :colon="false">
        <template slot="title">
          <img class="descriptions-icon" src="@/assets/img/titleIcon.png">
          <h4 class="descriptions-title"  style="margin-left: 8px;font-size:16px">盘点范围</h4>
        </template>
        <el-descriptions-item label="盘点平台：">{{formData.platform_names ? formData.platform_names : '全部'}}</el-descriptions-item>
        <el-descriptions-item label="盘点公司：">{{formData.company_names ? formData.company_names_temp : '全部'}}</el-descriptions-item>
        <el-descriptions-item label="资产状态：">
          <span v-if="this.formData.asset_statuses">
            <span  v-for="item in formData.asset_statuses_temp">
              {{ handleStatusClass(Number(item)).statusName }}
            </span>
          </span>
          <span v-else>全部(除退回厂内)</span>

        </el-descriptions-item>
      </el-descriptions>
    </div>
  </div>
</template>
<script>
import assetCommon from "@/views/AssetManagement/mixin/assetCommon";
import checkCommon from "@/views/AssetCheck/mixin/checkCommon";
import {getInventoryDtl} from "@/api/assetCheck";
import api from "@/api/tongji";
export default {
  name: "basicInfo",
  mixins: [checkCommon,assetCommon],
  data(){
    return{
      formData: {
        platform_names: '',
        asset_statuses_temp: []
      },
      platOptions: [],
    }
  },
  created() {
    this.platformFun()
    this.getDtl()
  },
  methods:{
    platformFun() {
      api.platform({}).then((res) => {
        this.platOptions = res.data.data
      })
    },
    getDtl(){
      getInventoryDtl({note_id: this.$route.query.note_id}).then(res => {
        this.formData = res.data
        if( this.formData.platform_ids){
          this.formData.platform_ids = this.formData.platform_ids.split(',')
        }
        let platform_names_temp = []
        if( this.formData.platform_ids &&  this.formData.platform_ids.length > 0){
          this.formData.platform_ids.forEach(item => {
            platform_names_temp.push(this.platOptions.find(i => i.platform === item).platform_unique)
          })
        }
        if(this.formData.company_names){
          this.formData.company_names_temp = this.formData.company_names.map(item=> item.organization_name).toString()
        }
        if(this.formData.platform_names){
          this.formData.platform_names =  platform_names_temp.toString()
        }
        if(this.formData.asset_statuses && this.formData.asset_statuses.length > 0){
          this.formData.asset_statuses_temp = this.formData.asset_statuses.split(',')
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/asset';

</style>